body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.react-pdf__Page__canvas{
	margin:auto;
	/* width:100% !important */
}
.swiper-button-next { color: #282c34 !important; }
.swiper-button-prev { color: #282c34 !important; }
@media screen and (max-width: 500px) {
	.swiper-button-next { display: none !important; }
	.swiper-button-prev { display: none !important; }
}

.swiper-button-disabled{
	display: none !important;
}