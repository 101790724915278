@font-face {
	font-family: 'poppinsblack';
	src: url('./assets/css/fonts/poppins-black-webfont.woff2') format('woff2'),
			 url('./assets/css/fonts/poppins-black-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'poppinsbold';
	src: url('./assets/css/fonts/poppins-bold-webfont.woff2') format('woff2'),
			 url('./assets/css/fonts/poppins-bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}



@font-face {
	font-family: 'poppinsregular';
	src: url('./assets/css/fonts/poppins-regular-webfont.woff2') format('woff2'),
			 url('./assets/css/fonts/poppins-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar{
	/* border-bottom: 1px solid #e5e5e5; */
	/* background-color: #23483E; */
	/* border-bottom: 1px solid #e5e5e5; */
	/* background-color: #23483E; */
	/* color: #23483E; */
	/* width: 100%; */
	/* float: right; */
	/* position: absolute; */
	height: 40px;
	line-height: 40px;
	/* margin-right: 50px; */
	/* text-align: right; */
}
/* .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
	bottom:0 !important;
} */
.swiper-pagination-bullet-active{
	--swiper-theme-color:#23483E
}
a{
	color:#23483E;
	text-decoration: none;
}
.image-upload>input {
  display: none;
}
input.stripe{
	padding: 10px 12px;
	width: 100%;
	color: #32325d;
	background-color: white;
	border: 1px solid transparent;
	border-radius: 4px;
	box-shadow: 0 1px 3px 0 #e6ebf1;
	transition: box-shadow 150ms ease;
	margin-bottom:10px;
	box-sizing: border-box;
}
input.stripe::placeholder{
	color: #A5B2C2;
	font-family: "Helvetica Neue", Helvetica, sans-serif;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
}